
import './Thankyou.css';
import featureImg from "../assets/images/images.jpg";
import { useState } from 'react';
import Thank_you_for_registering from "../assets/images/Thank_you_for_registering.png"

function Thankyou() {

  return (
    <div className="thankyou">'
    <div className="upper-section">
      <p class="thanks-text">Thank You for registering with</p>
      <img src={Thank_you_for_registering} />
    </div>
    <div className="lower-section">
      <p class="varification-text">Please check your email for login verification</p>
      <p class="varification-text">Please check your SPAM folder as well - it happens :(</p>
      </div>
    </div>
  );
}

export default Thankyou;
