
import './SignUp.css';
import featureImg from "../assets/images/images.jpg";
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

function Invitation() {
    let navigate = useNavigate(); 
    
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('level');
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    verification_email: "",
    errors: {},
    social_media: "",
    level: type
  });


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    const errors = {};

    // Check if username is empty
    if (!formData.first_name) {
      errors.first_name = "First name is required";
    }

    // Check if password is empty
    if (!formData.last_name) {
      errors.last_name = "Last name is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    }
    if (!formData.verification_email) {
      errors.verification_email = "Verification email is required";
    }

    if (formData.verification_email !== formData.email) {
      errors.verification_email = "Email and Verification email is not same.";
    }

    setFormData((prevState) => ({ ...prevState, errors }));

    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };
  console.log(formData);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      //window.location.href = 'https://create.markitiq.ai/';
        window.location.href = `http://campaignproofsliq.com/mail.php?first_name=${formData.first_name}&last_name=${formData.last_name}&social_media=${formData.social_media}&company_name=${formData.company_name}&email=${formData.email}&verify=markitiq&level=${type}`;
    //   fetch('http://campaignproofsliq.com/mail.php', {
    //     method: "POST"})
    //   .then((res) => {
    //     navigate('/thank-you');
    //     return res.json();
    //   });
      console.log(formData);
    } else {
      // Form is invalid, do nothing
    }
  };

  
  return (
    <div className="sign-up">
        <div class="upper-heading">
          <p>Let's get you started, please fill out the form below.</p>
          <p>You will be redirected to a thank you page once completed</p>
        </div>
        <div class="container" id="container">         
          
          <div class="overlay-container">
            <div class={`overlay ${type.replaceAll(' ', '')}`}>
              <div class="overlay-panel overlay-right">
                <h2>Hello, <br/> Welcome to the</h2>
                <div className="package"><h1>{type}</h1> <p>package</p></div>
                <p>Let's get you started!</p>
                  <p>Please enter your details and start Markit&nbsp;iQ</p>
              </div>
            </div>
          </div>

          <div class="form-container sign-up-container">
            <div class="overlay-mob-container">
               <div class={`overlay ${type.replaceAll(' ', '')}`}>
                <div class="overlay-panel overlay-right">
                  <h2>Hello, <br/> Welcome to the</h2>
                  <div className="package"><h1>{type}</h1> <p>package</p></div>
                  <p>Let's get you started!</p>
                  <p>Please enter your details and start Markit&nbsp;iQ</p>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit} className={`${type.replaceAll(' ', '')}`}>
              <div class="input-icons">
              <i class="fa fa-user icon"></i>
              <input type="text" name="first_name" className='input-field' placeholder="First Name" value={formData.first_name}
          onChange={handleChange}/>
              </div>
              {formData.errors.first_name && (
                <p className="error" style={{ color: "red" }}>{formData.errors.first_name}</p>
              )}
              <div class="input-icons">
              <i class="fa fa-user icon"></i>
              <input type="text" name="last_name" className='input-field' placeholder="Last Name" value={formData.last_name}
          onChange={handleChange}/>
              </div>
              {formData.errors.last_name && (
                <p className="error" style={{ color: "red" }}>{formData.errors.last_name}</p>
              )}
              <div class="input-icons">
              <i class="fa fa-user icon"></i>
              <input type="text" name="company_name" className='input-field' placeholder="Company Name" value={formData.company_name}
          onChange={handleChange}/>
              </div>

              <div class="input-icons">
              <i class="fa fa-envelope icon"></i>
              <input type="email" name="email" className='input-field' placeholder="Email" value={formData.email}
          onChange={handleChange}/>
              <input type="hidden" name="level" className='input-field' placeholder="" />
              </div>
              {formData.errors.email && (
                <p className="error" style={{ color: "red" }}>{formData.errors.email}</p>
              )}
              <div class="input-icons">
              <i class="fa fa-envelope icon"></i>
              <input type="email" name="verification_email"  className='input-field' placeholder="Verification  Email" value={formData.verification_email}
          onChange={handleChange}/>
              </div>
              {formData.errors.verification_email && (
                <p className="error" style={{ color: "red" }}>{formData.errors.verification_email}</p>
              )}
              
              <div class="rediobuttons-wrapper">
                    
                    <p className="radio-label">Please select where you heard about Markit iQ</p>
                    <div className="radio-section">
                      <div className="input-lavel">
                        <input onChange={handleChange} type="radio" id="LinkedIn" name="social_media" value="LinkedIn" />
                        <label for="LinkedIn">LinkedIn</label>
                        </div>
                        <div className="input-lavel">
                        <input onChange={handleChange} type="radio" id="Twitter" name="social_media" value="Twitter"/>
                        <label for="Twitter">Twitter</label>
                        </div><div className="input-lavel">
                        <input onChange={handleChange} type="radio" id="Instagram" name="social_media" value="Instagram"/>
                        <label for="Instagram">Instagram</label></div><div className="input-lavel">
                        <input onChange={handleChange} type="radio" id="Conference-Tradeshow" name="social_media" value="Conference-Tradeshow"/>
                        <label for="Conference-Tradeshow">Conference-Tradeshow</label></div><div className="input-lavel">
                        <input onChange={handleChange} type="radio" id="Startup-Community" name="social_media" value="Startup-Community"/>
                        <label for="Startup-Community">Startup Community</label></div><div className="input-lavel">
                        <input onChange={handleChange} type="radio" id="Other" name="social_media" value="Other"/>
                        <label for="Other">Other
                        </label></div>
                        {formData.social_media == "Other" && <input type="text" name="social_media_other"  className='input-field' placeholder="Other" value={formData.social_media_other}
              onChange={handleChange}/>}
                        </div>
                    </div>

              <input type="submit" value="Register" className="sign-up-btn"></input>
            </form>
          </div>
        </div>
    </div>
  );
}

export default Invitation;
