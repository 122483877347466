import React from 'react'

const WhatWeDeliver = () => {
    return (
        <div className='container'>
            <div className='row'>
                <div className='what-we-deliver-section'>
                    <div className='grid-section'>
                        <h2>What we deliver</h2>
                        <p>Data-driven creative campaigns that target consumers who match
                            a company's best customers in their likeness, language, interests and preferences.</p>
                    </div>

                    <div className='grid-section'>
                        <h2>Our Process </h2>
                        <p>We identify highly qualified prospects, combining client data with one of the largest network data bases in the US to surface information and insights about customers far beyond traditional profile data.</p>
                    </div>

                    <div className='grid-section'>
                        <h2>Growth unlocked</h2>
                        <p>Our Performance Analytics System provides real-time reporting and exact match-back of sales, providing accurate conversion rates and your return-on-investment.</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WhatWeDeliver