
import './Popip.css';
import { useNavigate } from "react-router-dom";
function Popip(props) {
    let navigate = useNavigate(); 
    const clickHandler = () => {
        props.setShowModal(false);
        navigate('/#pricing?invitation=true');
        var elmntToView = document.getElementById("pricing");
        elmntToView.scrollIntoView(); 
    }
    return (
          <div id="myModal" class="modal">
        <div class="modal-content">
            <span class="close" onClick={() => props.setShowModal(false)}>&times;</span>
            <div className="main-wrapper">
                <h1>Welcome to MarkitiQ! </h1>
                <p>We have a special access invite for Collision 2024 attendees.  Use code Collision24 at https://markitiq.ai/invitation and get your 3 month *complimentary monthly subscription pass to start reaching your intended audience today!</p>
            <p>Markit iQ has been the partner for agencies and printers over the last 3 years and now we are opening our subscription platform to help startups and small businesses of all sizes leverage data and insights to amplify their marketing needs.</p>
            <p><strong>*offer applies to monthly subscription fee only.  Does not include digital or print services or products. </strong></p>
            <a className="button" onClick={() => clickHandler()}>Click Here</a>
            </div>
        </div>

        </div> 
    );
  }
  
  export default Popip;
  