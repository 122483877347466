import { Link } from "react-router-dom";
import headerLogo from "../assets/images/logo.png"
import { Route, Switch, useLocation } from 'react-router-dom'
import { useEffect } from "react";
function Nav() {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <nav className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="row">
          <div className="navbar-header">
            <button
              data-target="#navbar-collapse-02"
              data-toggle="collapse"
              className="navbar-toggle"
              type="button"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a href="/" className="navbar-brand brand scrool">
            <img src={ headerLogo } alt="footer logo"/>
            </a>
          </div>

          <div id="navbar-collapse-02" className="collapse navbar-collapse">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a onClick={() => window.location = 'https://markitiq.ai/#home'}  href="https://markitiq.ai/#home">Home</a>
              </li>
              <li>
                <a onClick={() => window.location = 'https://markitiq.ai/#features'}  href="https://markitiq.ai/#features">Features</a>
              </li>
              {/* <li><a href="#portfolio">Portfolio</a></li> */}
              <li>
                <a onClick={() => window.location = 'https://markitiq.ai/#products'}  href="https://markitiq.ai/#products">Products</a>
              </li>
              <li>
                <a onClick={() => window.location = 'https://markitiq.ai/#managed-services'}  href="https://markitiq.ai/#managed-services">Managed Services</a>
              </li>
              <li>
                <a onClick={() => window.location = 'https://markitiq.ai/#campaign-manager'} href="https://markitiq.ai/#campaign-manager">Campaign Manager</a>
              </li>
              <li>
                <a  onClick={() => window.location = 'https://markitiq.ai/#pricing'} href="https://markitiq.ai/#pricing">Pricing</a>
              </li>
              <li><a onClick={() => window.location = 'https://app.markitiq.ai/sign-in'} href="https://app.markitiq.ai/sign-in" className="purchase scrool nav-contact btn-signin">Sign In </a></li>
              {/* <li><a href="#blog">Blog</a></li> */}

              <li>
                <a href="#contact" className="purchase scrool nav-contact">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#contact" className="nav-mobile-contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
