
import img3 from '../assets/images/01.jpg'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function Service4() {
    return (
        <section id="Service">
           
            <div className="service-layout">
                {/* <h1>POWER ANALYITICS</h1> */}
                <div className="service-content">
                <div className='service-content-left'>
                        <img src={img3}/>
                    </div>
                    <div className='service-content-right'>
                        <Carousel emulateTouch swipeable showArrows={true} axis='horizontal' showThumbs={false} className='carousel'>
                        <div className='carousel-item' >
                                {/* <img style={{width:'100px'}} src={img1}/> */}
                                <h1>DATA</h1>
                                <ul>
                                    <li>
                                        Data is the new black and must be utilized. It can be an extremely effective tool for
    customer acquisition, if used properly.
                                    </li>
                                    <li>
                                    High Performance Campaigns - Devise the best plan before launching your campaign -
Go beyond Google Ads, SEO, Digital Ads, and websites to build lasting relationships.
Envision a campaign that feels like the real customer experience. Plan for performance
and get a competitive edge to keep up with your ever-changing business objectives.
Markit IQ services are continuously tested and improved to support new and emerging
marketing channels to store and analyze collected client data that converge with the
evolving needs of your growing business.
                                    </li>
                                    <li>
                                    Leverage and implement technology and processes that empower you to equip your
whole organization with business intelligence on your objectives, audience, growth
strategies, most effective purchase and marketing channels.
                                    </li>
                                </ul>
                                <span><a href="#pricing" className="btn btn-orange btn-lg scrool" style={{fontSize:'13px'}}>GET STARTED NOW</a></span>
                            </div>
                            <div className='carousel-item'>
                                {/* <img style={{width:'100px'}} src={img1}/> */}
                                <h1 >MARKETING CAMPAIGNS</h1>
                                <ul>
                                    <h6>Strategic options Multichannel marketing</h6>
                                    <li style={{fontSize:'15px'}}>Increase the touch point by incorporating a follow-up telemarketing campaign or email
campaign in conjunction with your postal campaign.
                                    </li>
                                   
                                    <h6>Multichannel marketing</h6>
                                    <li style={{fontSize:'15px'}}>
                                    Increase the touch point by incorporating a follow-up telemarketing campaign or email
campaign in conjunction with your postal campaign.
                                    </li>
                                    <h6>Split testing</h6>
                                    <li style={{fontSize:'15px'}}>Testing out various subject lines and audience segments will isolate the areas that are
delivering the highest ROI and allow for a roll-out to the proven winner.
                                    </li>
                                 
                                </ul>
                                <span><a href="#pricing" className="btn btn-purple btn-lg scrool" style={{fontSize:'13px'}}>GET STARTED NOW</a></span>
                            </div>
                            <div className='carousel-item'>
                                {/* <img style={{width:'50px'}} src={img3}/> */}
                                <h1>MARKETING CAMPAIGNS (CONT.)
                                    </h1>
                                   
                                <ul>
                                   <h6 style={{marginTop:'30px'}}>Frequency mailing</h6>
                                    <li style={{fontSize:'15px'}}>For a proven lift to overall campaign performance, Markit IQ works to develop an
impactful re-mail strategy.
                                    </li>
                                    <h6>Analytics</h6>
                                    <li style={{fontSize:'15px'}}>Provided the responder universe is large enough, our expert analyst team will create
poignant campaign profiles to help drive future marketing decisions relative to creative
design and target markets.
                                    </li>
                                    <span><a href="#pricing" className="btn btn-blue btn-lg scrool" style={{fontSize:'13px'}}>GET STARTED NOW</a></span>
                                </ul>
                            </div>
                            <div className='carousel-item'>
                                {/* <img style={{width:'50px'}} src={img2}/> */}
                                <h1>CLONE MODELLING</h1>
                                <ul>
                                    <li>
                                    When you are facing increased competition and your acquisition efforts and more
                                        challenging, membership is stagnant and conversion opportunities are reduced, Markit
                                        IQ clone modelling can help.
                                    </li>
                                    <li>
                                    Reduce marketing acquisition costs and convert prospects into highly engaged
                                    customers.
                                    </li>
                         
                                    <h5>
                                    A remarkable solution that predicts how closely prospects resemble your “best”
                                        customers. The best approach is centered around:
                                        </h5>

                                            <li>
                                            identifying your existing best customers
                                            </li>
                                            <li>
                                            employing advanced statistical analysis and custom modeling to identify prospect
                                        households who “look like” your best customers.
                                            </li>
                                            <li>
                                            reaching these “best” prospects through a cross-channel media strategy
                                        designed to engage and convert these prospects to customers.
                                            </li>

                                   
                                </ul>
                                <span><a href="#pricing" className="btn btn-orange btn-lg scrool" style={{fontSize:'13px'}}>GET STARTED NOW</a></span>
                            </div>
                            <div className='carousel-item' >
                                {/* <img style={{width:'50px'}} src={img3}/> */}
                                <h1>CLONE MODELLING (CONT.)</h1>
                                <h6>Steps to Clone Modelling</h6>
                                <ul>
                                    <li>
                                    Define the customer segment you want to clone - define your prospect audience.
                                    </li>
                                    <li>
                                    Identify the customer segment to be cloned based upon a key financial metric.
                                    </li>
                                    <li>
                                    Append 3rd party demographic, lifestyle and behavioral data.
                                    </li>
                                    <li>
                                    Build the clone model - identify attributes that will represent the attributes of your
best customer - examine gross sales, gross margin operating profit or other
financial metrics.
                                    </li>
                                
                                 
                                </ul>
                                
                                       
                           
                                <span><a href="#pricing" className="btn btn-purple btn-lg scrool" style={{fontSize:'13px'}}>GET STARTED NOW</a></span>
                            </div>
                            <div className='carousel-item'>
                                {/* <img style={{width:'50px'}} src={img3}/> */}
                                <h1>CLONE MODELLING (CONT.)</h1>
                                <h6>Steps to Clone Modelling</h6>
                                <ul>
                               
                                    <li>
                                    Score the prospect universe - determine which prospects are most likely to look
like your best customers.
                                    </li>
                                    <li>
                                    Eliminate your existing customers - including existing customers is a waste of
advertising capital.
                                    </li>
                           
                                </ul>
                                
                                       
                           
                                <span><a href="#pricing" className="btn btn-purple btn-lg scrool" style={{fontSize:'13px'}}>GET STARTED NOW</a></span>
                            </div>
                            <div className='carousel-item'>
                                {/* <img style={{width:'50px'}} src={img3}/> */}
                                <h1>CLONE MODELLING (CONT.)</h1>
                                <h6>Steps to Clone Modelling</h6>
                                <ul>
                                    <li>
                                    Eliminate your existing customers - including existing customers is a waste of
advertising capital.
                                    </li>
                                    <li>
                                    Reach out to the clone model target audience - utilize a variety of messaging
strategies to engage these prospects through any addressable marketing
channel: direct mail, email, display, social and/or mobile digital media.
                                    </li>
                                </ul>
                                
                                       
                           
                                <span><a href="#pricing" className="btn btn-purple btn-lg scrool" style={{fontSize:'13px'}}>GET STARTED NOW</a></span>
                            </div>
                            <div className='carousel-item'>
                                {/* <img style={{width:'50px'}} src={img2}/> */}
                                <h1 >POWER ANALYITICS</h1>
                                <ul>
                                    <li>
                                    Leverage the Markit IQ hub to impeccably store and integrate key metrics into your
preferred data visualization, analytics tools.
                                    </li>
                                    <li>
                                    Benefit from automatic updates in data visualization, web analytics, and storage.
                                    </li>
                                    <li>
                                    Ensure your campaigns can launch faster, scale easier, and are more personalized to
your customers and their needs.
                                    </li>
                                   
                                </ul>
                                <span><a href="#pricing" className="btn btn-blue btn-lg scrool" style={{fontSize:'13px'}}>GET STARTED NOW</a></span>
                                
                            </div>
                        </Carousel>
                        
                    </div>
                   
                </div>
             
            </div>
        </section>      
    );
  }
  
  export default Service4;
  