import CarouselImg2 from '../assets/images/markitiq_bnr.jpg'
import CarouselImg1 from '../assets/images/markitiq_bnr-2.jpg'
import ImgPrev from '../assets/images/prev-icon.svg'
import ImgNext from '../assets/images/next-icon.svg'

import vid from '../assets/video/home_vid.mov'
import { useEffect, useState } from 'react'
import Popip from './Popip'

function Home() {
  const [number, setNumber] = useState(1);
  const [text, setText] = useState("MARKIT iQ PROVEN PERFORMANCE NEW CUSTOMER ACQUISITION +X% VALUED CUSTOMERS");

    useEffect(() => {
      const interval = setInterval(() => {
        if (number >= 3) {
          setNumber(1)
          setText("MARKIT iQ PROVEN PERFORMANCE NEW CUSTOMER ACQUISITION +X% VALUED CUSTOMERS")
        }
        if (number == 2) {
          setNumber(3)
          setText("INCREASED CUSTOMER GROWTH ENGAGE WITH YOUR EXISTING AUDIENCE")
        }
        if (number == 1) {
          setNumber(2)
          setText("INCREMENTAL REVENUE, DECREASE IN COST PER ACQUISITION REDUCTION IN MARKETING SPEND")
        }
        
        
      }, 5667);
      return () => {
        clearInterval(interval);
      };
    }, [number]);

    

    // useEffect(() => {
      
    //     var element = document.getElementById('videoTag');
    //     element.muted = "muted";
    // }, []);

  return (
    <section id="home" className="home-wrapper">
      {/* <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner" role="listbox">
          <div class="item active">
            <div class="carousel-caption">
              <h1 className="home-title wow fadeIn" data-wow-delay="0.5s">ELEVATE YOUR BRAND WITH DATA DRIVEN INSIGHTS & STRATEGIES
              </h1>
              <p className="home-subtitle wow fadeIn" data-wow-delay="1s">
                Market IQ tailored solutions keep you competitive and on the path to success. Our unique online tools provide what you need to create highly effective marketing strategies.
              </p>
              <a href="#products" className="btn btn-lg btn-blue scrool wow fadeIn" data-wow-delay="1.5s">Products</a>
              <a href="#campaign-manager" className="btn btn-lg btn-margin btn-white scrool wow fadeIn" data-wow-delay="1.75s">Services</a>
            </div>
            <div className="gradient_overlay" />
            <img src={CarouselImg1} alt="image" />
          </div>

          <div class="item">
            <div class="carousel-caption">
              <h1 className="home-title wow fadeIn" data-wow-delay="0.5s">Engage with intelligent data-driven marketing
              </h1>
              <p className="home-subtitle wow fadeIn" data-wow-delay="1s">
              Improve your customer acquisition and loyalty with Markit iQ.
Our campaign builder reduces cost and increases speed-to-market right at your fingertips.
              </p>
              <a href="#products" className="btn btn-lg btn-blue scrool wow fadeIn" data-wow-delay="1.5s">Products</a>
              <a href="#campaign-manager" className="btn btn-lg btn-margin btn-white scrool wow fadeIn" data-wow-delay="1.75s">Services</a>
            </div>

            <div className="gradient_overlay" />
            <img src={CarouselImg2} alt="image" />

          </div>
        </div>


        <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
          <span class="glyphicon glyphicon-chevron-left" aria-hidden="true">
            <img src={ImgPrev} alt='icon' />
          </span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
          <span class="glyphicon glyphicon-chevron-right" aria-hidden="true">
            <img src={ImgNext} alt='icon' />
          </span>
          <span class="sr-only">Next</span>
        </a>
      </div> */}
      <div className='videoTag'>
      <video autoplay="autoplay" loop muted playsInline src={vid} type='video/mp4'  id="videoTag">
      </video>
      </div>
      
      <div className="container-video">
        <div >
          <p className="slide1">
            {text}

            <div className="is-content ">
              <a href="#pricing">
                <div className="inner"> Learn more</div>
              </a>

            </div>
          </p>
          
        </div>
      </div>
    </section>

  );
}

export default Home;
